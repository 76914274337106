export interface AppConfigInterface {
	App: {
		baseUrl: string;
		apiUrl: string;
	};
	Auth: {
		audience: string;
		domain: string;
		clientId: string;
		skip: boolean;
	};
	gRPC: {
		hostname: string;
		ocld: string;
	};
	SoCld: {
		deployEnv: 'production' | 'test';
	};
	isProd: boolean;
}

export const AppConfig: AppConfigInterface = {
	App: {
		baseUrl: import.meta.env.VITE_APP_BASE_URL || globalThis?.location?.origin,
		apiUrl: import.meta.env.VITE_APP_API_URL
	},
	Auth: {
		audience: import.meta.env.VITE_AUTH_AUDIENCE,
		domain: import.meta.env.VITE_AUTH_DOMAIN,
		clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
		skip: import.meta.env.MODE === 'mocks'
	},
	gRPC: {
		hostname: import.meta.env.VITE_GRPC_HOSTNAME,
		ocld: import.meta.env.VITE_OCLD_URL
	},
	isProd: import.meta.env.VITE_PRODUCTION === 'true',
	SoCld: {
		deployEnv: import.meta.env.VITE_SOCLD_DEPLOY_ENV
	}
};

if (Boolean(AppConfig.App.apiUrl) === false) {
	console.error('Application is not configured correctly missing API Url');
}
